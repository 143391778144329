html,
body,
#root,
.root-child {
  height: 100%;
}
*,
:after,
:before {
  box-sizing: border-box;
}
body {
  letter-spacing: initial;
  margin: 0;
  font: 14px/1 Questrial, sans-serif;
  font-size: 0.875rem;
  color: #000;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

.root-child {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

a {
  line-height: initial;
}

footer#footer {
  margin-top: auto;
  padding: 10px 0;
}
body {
  font-family: "Helvetica LT W05 Roman", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
}

.main-grid {
  height: 525px;
}

*:not(div:not(.focusAble)):focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

/* IE11 only start*/
*::-ms-backdrop,
*:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

/* IE11 only end*/

select[disabled] {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

input#search-input.search-form-input {
  padding: 0 5px;
  border-bottom: 1px solid #444 !important;
  text-align: right;
}

#searchForm {
  position: relative;
}

#searchForm.active input#search-input.search-form-input {
  text-align: left;
}

.review-process-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.review-process-loading-wrapper {
  padding: 30px;
  height: 200px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.reviewProcessLoading {
  width: 100%;
  height: 10px;
}
.reviewProcessLoading > div {
  height: 100%;
  width: 100%;
}

.preview-control {
  position: relative;
  display: none;
  background-color: #e6e7e9;
  color: #0060f0;
  font-weight: 600;
  font-size: 1rem;
  padding: 5px 2.5%;

  border-top: 1px solid #0060f0;
  border-bottom: 1px solid #0060f0;
}

#search-mobile-icon-btn {
  color: #000;
  cursor: pointer;
}

ul.staticMenu li {
  text-align: left;
}

ul.staticMenu li a {
  display: flex;
  align-items: center;
}

.mobile-nav-child-cats {
  display: none;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
}
.mobile-menu-item.active > .mobile-nav-child-cats {
  display: flex;
}

/*PAGINATION DESIGN START*/

.pagination li {
  cursor: pointer;
  padding: 5px 7px;
  float: left !important;
  width: auto;
  display: block;
  float: right;
  margin: 0px;
  overflow: hidden;
  width: auto;
  position: relative;
  line-height: 10px;
  text-decoration: none;
  font-size: 16px;
  color: #0060f0;
  font-weight: 600;
}

.pagination .selected {
  color: #0051ca;
  text-decoration: underline;
}

ul.pagination li.first.buttonPageLeft {
  border-left: none;
  border-radius: initial;
  font-weight: 500;
}

ul.pagination li.last {
  font-weight: 500;
}

/*PAGINATION DESIGN ENDS*/

/* NAV MENU START */
.dropdown-content5 li {
  padding: 0px !important;
}
.user-profile-dropdown {
  width: 100%;
  margin: 0 auto;
}
li.dropdown5 {
  display: inline-block;
}

.dropdown-content5 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 11px;
}

.dropdown-content5 a {
  color: black !important;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 10px 10px !important;
  margin: 0px 0px !important;
}

.dropdown5:hover:not(.mobile) .dropdown-content5.level-2,
.dropdown-content5.level-2.active {
  display: block;
}

.subCat--item:hover:not(.mobile) > a > .dropdown-content5 {
  display: block;
}

.cate {
  widows: 100%;
  margin: 0 auto;
}

.cate li {
  padding: 11px 11px 11px 11px;
}
.dropdown-content5 li {
  padding: 0px !important;
}
.ab {
  line-height: 80px;
  margin-left: 0%;
}

.cate li a,
.cate li span {
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
  white-space: nowrap;
}

.NaviMenu {
  width: auto !important;
  background-color: #ddd !important;
}

.NaviMenuDwtc {
  width: auto;
  margin-right: 40px;
}

.nav-extended {
  background-color: #fff;
}

nav.nav-extended .nav-wrapper {
  height: 100% !important;
  border: none !important;
}

nav.nav-extended .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-icons-container {
  display: flex;
  align-items: center;
}
.menu-dwtc {
  flex: auto;
  justify-content: space-between;
}

.icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.login-icon-container {
  position: relative;
  box-shadow: inset 1px 0 rgba(0, 0, 0, 0.3);
}

.login-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.login-text-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-text-container span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav-icons-container .icon-container {
  padding-left: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-icons-container i {
  font-size: 33px;
}

.nav-icons-container i,
.nav-icons-container span {
  line-height: initial;
}

.locationicon {
  float: left;
  margin-right: 6px;
  width: 22px;
  padding-top: 3px;
}

.icon-wrapper i {
  color: #444;
  white-space: nowrap;
  font-size: 30px !important;
}

.icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: #444;
  font-size: 13px;
  font-weight: 500;
}

.icon-title {
  color: #000;
  white-space: nowrap;
  margin-left: 7px;
}

.icon--text {
  color: #000;
}

.icon-action-text {
  color: #0060f0 !important;
  text-decoration: underline !important;
}

.profile-action-link {
  color: #0060f0 !important;
  text-decoration: underline !important;
}

.searchInfo {
  display: flex;
  border-radius: 4px;
  margin-left: 30px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  width: 600px;
  align-items: center;
  left: 25.5%;
  height: 50px;
}

form#searchForm {
  flex-direction: row;
  box-sizing: border-box;
}

.search-form-container,
form#searchForm {
  display: flex;
  align-items: center;
}

form#searchForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 30px;

  padding-right: 10px;
  padding-left: 0px;
}

form#searchForm.active {
  min-width: 80px;
}

form#searchForm i {
  line-height: 30px !important;
  height: inherit;
  color: #444;
  font-size: 24px !important;
  margin: 0px 0px 0px 10px;
}

form#searchForm:not(.active) label::after {
  color: #444;
  margin-left: 4px;
  font-size: 12px;
}

form#searchForm label {
  height: inherit;
  line-height: initial !important;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.search-form-container {
  display: flex;
  align-items: center;
  border-right: 0px solid #cdcdcd;
  padding-right: 0px;
  height: 80px;
  margin-right: 40px;
}

#search-input {
  border: 0 !important;
  box-shadow: none;
  background-color: transparent;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 2.25px;
  height: 100%;
  font-weight: 700;
  width: 220px;
  transition: width 0.4s ease-in-out;
  line-height: 24px;
  border-radius: 0px !important;
  color: #444 !important;
  outline: none;
}

#search-input {
  color: #444 !important;
}

#search-input::placeholder {
  color: #444 !important;
}

.sortByMargin {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  background-image: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0px;
  font-size: 13px !important;
}

select.form-control {
  width: 100%;
}

.sortby-wrapper {
  display: flex;
  align-items: center;
}

.sortby-wrapper label {
  margin: 0;
  font-size: 1em;
  margin-right: 7px;
  padding: 5px 0;
}

#sortby {
  display: flex;
  width: 160px;
  float: right;
  /* border: none !important; */
  box-shadow: none !important;
  font-size: 12px !important;
  padding-left: 10px !important;
  border: 1px solid #cfd0d2;
  border-radius: 0px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.positionAb {
  z-index: 2;
  top: 0;
  position: fixed;
  width: 100%;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.positionAb[aria-collapsed="true"] {
  transform: translateY(-100%);
}

.site-header1.static1,
.site-header1.sticky {
  background: #fff;
  position: fixed;
  z-index: 100;
  width: 100%;
  animation: none;
  box-shadow: 0 3px 3px #cdcdcd;
}
.react-multi-carousel-item {
  margin-right: 0px !important;
  flex: 0 0 auto !important;
}

.featured-item-con.space-gap .react-multi-carousel-track {
  width: 100%;
  gap: 100px;
  padding: 0 100px;
}

.featured-item-con.space-evenly .react-multi-carousel-track {
  width: 100%;
  justify-content: space-evenly;
}

.react-multi-carousel-item {
  /* margin-right: 10px; */
  transform-style: preserve-3d;
  text-align: center;
  width: 100%;
  height: 100%;
  /* line-height: 300px; */
  vertical-align: middle;
  /* box-shadow: 0 0 2px #ddd; */
  padding: 5px;
}

.react-multi-carousel-item img {
  max-height: 100%;
  height: 150px;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-multi-carousel-item p {
  font-size: 16px;
}

.react-multi-carousel-item span {
  font-size: 16px;
}

button {
  border: none;
}
a,
a.active,
a:active,
a:focus,
a:hover,
a:visited {
  color: #000;
  text-decoration: none;
}

.new-footer {
  width: 95%;
  clear: both;
  margin-top: 0px;
  margin-left: 2.5%;
}

.new-footer h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 700;
}

.new-footer .footer-links {
  line-height: 1.67em;
}

.footer-links a:hover {
  text-decoration: underline;
  text-decoration-color: #ccc;
}
.new-footer p {
  line-height: 2em;
  font-size: 1em;
  margin-top: 10px;
}

.footerLogo img,
.new-footer p a {
  margin-top: 10px;
}

.new-footer p a {
  margin-left: 0;
}

figure {
  margin: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.facet-has-checked-option {
  color: #000;
}

.more-options-button-wrapper {
  float: right;
  margin-top: 5px;
}

.more-options {
  max-height: 100%;
  transition: all 1s linear;
}

.checkmark-box {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  cursor: pointer;
  margin: 0 5px 0 0 !important;
  border: 1px solid #444;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 2px;
}

div.facetFilterContent li:hover label.checkmark-box {
  background-color: #ffffff;
}

.priceFacet .material-icons {
  top: 0px !important;
  font-size: 19px !important;
  line-height: initial !important;
}

.material-icons.lighton::before,
.material-icons.lightoff::before {
  content: "" !important;
  display: inline-block;
  width: 17px;
  height: 1.75em;
  margin: 0 5px !important;
}

.checkmark-box.checked::after {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  background-color: #e22726;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.facet-crumb {
  display: inline-block;
  margin-right: 10px;
}

.facet-crumb span {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #c4eafb;
  padding: 5px 7px;
  border-radius: 16px;
  font-size: 13px;
  margin-bottom: 2px;
}

.sub-nav-menu h2 {
  font-weight: 500;
  text-align: left;
  font-size: 27px;
  margin: 0 5%;
}

.facet-crumb-cancel span {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #000;
  padding: 5px 7px;
  border-radius: 16px;
  color: #fff;
  margin-bottom: 2px;
}

.facet-crumb-cancel i {
  color: #fff;
}

.facet-crumb:hover,
.facet-crumb-cancel:hover {
  background-color: none;
}

.facetCrumbRow {
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  padding: 10px 1% 10px 20px;
  position: relative;
}

h2.facetTitle {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  padding: 10px 0px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facetFilterWrapper {
  max-height: 0px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #666 #ccc;
  padding: 5px;
}

.facetFilterWrapper.active {
  margin-top: 10px;
  max-height: 160px;
}

.facetFilterWrapper.active.view-more-active {
  max-height: 100%;
}

.productCount {
  font-size: 13px;
  color: #555;
  margin-left: 4px;
  top: 0px !important;
  /*! position: relative; */
  /*! bottom: 20px; */
}

.productText {
  font-size: 15px !important;
  letter-spacing: 0.5px;
  color: #6a6a6a;
  padding-top: 3px;
  padding-left: 10px;
}

.facetFilterContent:not(:last-of-type) {
  margin-bottom: 5px;
}

.facetFilterContent > ul > li {
  letter-spacing: initial;
  line-height: initial;
  display: inline-flex;
  vertical-align: top;
  margin-bottom: 6px;
  align-items: center;
  width: 100%;
}

.priceFacet .facetFilterContent:last-child {
  margin-bottom: 0px;
}

.next {
  font-size: 15px !important;
}

.last {
  font-size: 15px !important;
}

.first {
  font-size: 15px !important;
}

.prev {
  font-size: 15px !important;
}

.facetsContent {
  padding: 20px !important;
  box-shadow: 0 0 4px #f1f1f1;
}

.filt {
  display: flex;
  line-height: 30px;
  font-weight: bold;
  font-size: 19px;
  color: #6a6a6a;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  font-family: "Source Sans Pro", sans-serif;
}

.breadcrumb {
  font-size: 12px !important;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 4px 0 11px 0;
  margin-top: 0px !important;
}

.breadcrumb > li {
  text-transform: initial !important;
  font-weight: 600 !important;
  margin: 0 !important;
  display: flex;
}

.breadcrumb i {
  position: relative;
  top: 4px;
  font-size: 19px;
  margin: 0px 4px 0px 0px;
  color: #666;
}

ul.breadcrumb li a,
ul.breadcrumb span {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  color: #0060f0;
  text-decoration: underline;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.ac-skin .item-details .title,
.itemTitleMobile {
  font-size: 1.8em;
  font-weight: 600;
}

.item-main {
  width: 85%;
  margin: 0 auto;
}

select {
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 3rem;
}

h6 {
  font-size: 1.08em;
}
.form-control {
  height: 34px;
}

table {
  width: 100%;
  display: table;
}
b,
strong {
  font-weight: 700 !important;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="radio"] {
  height: auto;
  margin: 0;
  width: auto;
  line-height: normal;
}

/* NAV MENU END */

.logo-text {
  margin-left: 10px;
}
.logo-text p {
  color: #000;
  font-weight: 600;
  text-align: left;
  line-height: 1.5;
  margin: 0;
}

.mobile-nav-child-cats a {
  color: #fff !important;
  font-size: 1em !important;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 25px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.menu-btn-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.menu-btn-wrapper i {
  cursor: pointer;
}

.logo-container {
  height: 100%;
}
.brand-logo,
.brand-logo img {
  height: 100%;
}

.header-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.home-item {
  box-shadow: 0 0 1px 2px #1f638c;
}

.item-card-figure {
  background-color: #c4eafb;
  height: 100% !important;
  width: 100% !important;
  padding: 5px;
}

.product-title {
  font-weight: 600;
  font-size: 14px;
}

.item-code-wrapper,
.item-code-wrapper > div {
  color: #000 !important;
}

.product-price--main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-price--main > div {
  float: unset;
  display: flex;
  width: auto;
}

.product-price--main--left {
  font-style: italic;
}

.configure {
  font-style: italic;
  background-color: #0060f0;
  color: #fff;
}

.configure:hover {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
}

.dropdown5 {
  position: relative;
}
.dropbtn5 {
  display: flex;
  align-items: center;
}

.dropdown-content5 {
  background-color: #ffffff;
  color: #1c1c1c;
  margin: 0;
  top: 46px;
  left: 0px;
  z-index: 2;
  box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 50%);
  border-radius: 4px;
}

.dropdown-content5.level-2 {
}

.cate li {
  padding-left: 0;
  display: flex;
}

.cate li a,
.cate li span {
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
/* .cate li a:hover,
.cate li a:active,
.cate li a:focus {
  color: #fff !important;
}
 */
.subCat--item.static-width {
  min-width: 350px;
}

.subCat--item span {
}

.subCat--item.not-visible {
  display: none;
}

.subCat--item > a > ul {
  top: 0;
  left: 100%;
}

.dropdown-content5 li {
  background: #fff;
  border-bottom: 1px solid #8c8c8c;
  border-left: 1px solid #8c8c8c;
  color: #0060f0 !important;
  position: relative;
  z-index: 2;
}
.dropdown-content5 li:last-child {
  border-bottom: none;
}

.dropdown-content5 li a:hover,
.dropdown-content5 li a:focus,
.dropdown-content5 li a:active {
  color: #fff !important;
  background-color: #0060f0 !important;
}
.dropdown-content5 a,
.dropdown-content5 span {
  color: #0060f0 !important;
}
.dropdown-content5 span {
  color: inherit !important;
}
.carousel-container {
  padding: 0;
  padding-bottom: 20px;
}

.parent-cat-wrapper {
  width: 100%;
}

.main-category-promotion {
  background-color: #c4eafb;
  color: #000;
  line-height: 1.8rem;
  font-size: 1.4rem;
  padding: 13px 2.5% 8px 2.5%;
  margin-top: -15px;
  margin-bottom: 10px;
}

.main-category-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}

.main-category-wrapper {
  position: relative;
  height: 100%;
  border-radius: 50%;
  display: flex;
}

.main-category-wrapper figure {
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  background-size: contain;
  width: 70%;
  height: 70%;
  margin: 0 auto;
  position: initial;
}
.featured-item-con {
  padding-top: 30px;
}

.parent-cat-wrapper h1,
.featured-item-con h2,
.category-title {
  margin-top: 20px;
  margin-bottom: 10px !important;
  color: #231f20;
  line-height: inherit;
  font-size: 1.8em;
  font-weight: 600;
}

.featured-item-con .home-item {
  height: 330px !important;
}
.featured-item-con .owl-item .product-price {
  margin: 0;
}

.featured-item-con .item-card-image {
  height: 200px;
}

.category-title {
  margin-top: 0;
  font-size: 1.8em;
}

.main-category-wrapper p {
  bottom: unset;
  margin-top: 15px;
  color: #333;
  font-size: 1.1em;
  top: 100%;
  left: 0;
  letter-spacing: initial;
  line-height: initial;
}

.divider-gray-gradient {
  margin-bottom: 20px;

  height: 5px;
  width: 100%;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    90deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(210, 210, 210, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.main-category-item {
  border-radius: 50%;
  height: 90%;
  width: 90%;
  background-color: #c4eafb;
  border: 2px solid #1e628a;
  position: absolute;
  top: 0;
  margin-left: 2.5%;
}

.main-category-item a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.NaviMenu {
  background-color: #0060f0 !important;
  margin: 0;
}
.facetsContent {
  box-shadow: 0 0 1px 2px #999;
  background-color: #f2f3f4;
  padding: 20px !important;
}

.filt {
  padding-bottom: 0;
  border-bottom: 2px solid #999;
}

.facetTitleWrapper {
  margin-top: 5px;
  background-color: #fff;
  padding: 5px;
}

i.facet-toggle-icon {
  font-weight: 600;
  font-size: 24px;
}
.category-title-sort-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.reviewInProgressWrapper {
  background-color: #faf39a;
  color: #000;
  box-shadow: 0 0 0 1px #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 10px;
  font-size: 0.8rem;
  transform: translateY(-100%) translateY(1px);
}

.reviewInProgressWrapper.personalized-item {
  top: -34px;
}

.pagination-container {
  padding: 0;
}

.item-card-container {
  margin: 0;
}

.item-card-item {
  padding: 10px;
}

.product-page-title-code-wrapper {
  display: flex;
  justify-content: space-between;
}

.product-details-specs-wrapper {
  min-width: 100%;
  height: 100%;
  width: unset;
  margin: 0 !important;
}

#product-specs-container {
  font-size: 1rem;
}

#product-specs-container tr {
  width: 100%;
}

#product-specs-container tr td {
  border: 2px solid #fff;
  padding: 8px;
}

#product-specs-container tr td:nth-child(2n-1) {
  background-color: #e6e7e8;
}

#product-specs-container tr td:nth-child(2n) {
  background-color: #f1f1f2;
}

.products-specs-content {
  padding: 1rem;
}

.products-specs-content a {
  color: #0060f0 !important;
  text-decoration: underline;
}

.products-specs-content p {
  margin: 10px 0;
}

.product-specs-wrapper {
  width: 100%;
}
.product-specs-wrapper h2 {
  font-size: 1rem !important;
  padding: 5px 10px !important;
  display: flex;
  align-items: center;
  background: #e6e7e8;
}

.product-specs-wrapper h2 > i,
.product-page-specs-btn i {
  padding-right: 5px;
  color: #1074bc;
}

#product-specs-container ul {
  list-style: disc;
  line-height: 2;
}

#product-specs-container ul li::marker {
  font-size: 18px;
}

#product-specs-container ul li {
  margin-left: 20px;
}

.product-page-specs-btn:focus,
#product-specs-container:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

.product-page-specs-btn {
  display: flex;
  align-items: center;
}
.product-page-specs-btn span {
  text-decoration: underline;
  color: #0060f0;
  font-size: 14px;
  cursor: pointer;
}
.specs-info-container {
  display: flex;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
}

.product-details-specs-container {
  width: 100%;
}

.c-full {
  width: 45%;
  flex-direction: column;
}
.c-left {
  margin: 0;
  margin-bottom: 10px;
}
.c-right,
.c-left {
  width: 100%;
  height: 50%;
}

.item-main,
.sub-nav-bread {
  width: 95%;
}

#leftGrid {
  width: 55%;
  min-width: unset;
  margin: 0;
  width: 55%;
  width: calc(55% - 20px);
}

#bd {
  margin-top: 140px !important;
  margin-bottom: 70px !important;
}

.header-container[data-hidden="true"] ~ #bd {
  /*   padding-top: 40px !important; */
}

.signin-message-wrapper {
  padding: 10px;
  color: rgb(204, 0, 0);
  text-align: center;
}

.imageCarousel-canvas {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.last_breadcrumb,
.breadcrumb-link {
  color: #09aede;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 14px;
}

.breadcrumb > li > a > i {
  margin: 0;
}

.first-package-price-wrapper {
  border-bottom: 2px solid #78a9a9;
  padding-bottom: 5px;
}

.first-package-price {
  font-size: 20px !important;
  color: #000;
}
.first-package-price.strikethrough {
  font-weight: 400;
  text-decoration: line-through;
}
.first-package-unit,
.first-package-price-starting-from {
  font-size: 16px !important;
}
.add-to-cart-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-to-cart-previous-btn {
  margin-right: 30px;
  cursor: pointer;
}

.add-to-cart-btn-text {
  cursor: pointer;
}

.add-to-cart-grand-total-wrapper p {
  font-size: 1.08em;
  font-weight: 600;
}

.add-to-cart-btn-text span {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
}

.regularItemTitle {
  width: calc(55% - 20px) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-page-item-code {
  align-self: flex-end;
}

.item-top-row {
  padding-top: 10px;
}

.main-category--see-more-details {
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  background-color: transparent;
}

.promotion-modal-wrapper {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
}

.promotion-modal-wrapper h1 {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  font-size: 1.5rem;
}

.main-category-modal-content {
  padding: 20px;
}

.main-category-modal-header {
  position: relative;
  background-color: #0060f0;
  color: #fff;
}

.main-category-modal-header img {
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.main-category-modal-content p {
  margin: 0;
  font-size: 1.2rem;
  line-height: initial;
}
.main-category-modal-content button {
  background-color: #0060f0;
  color: #fff;
  padding: 7px 40px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
  margin-left: auto;
  display: flex;
}

.footer-expand-button {
  position: absolute;
  background: #636f72;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36px;
  padding: 0;
  padding-top: 3px;
  font-size: 0.7rem;
  color: #eee;
}

@media all and (min-width: 601) {
  .nav-extended {
    height: 34px;
    line-height: 34px;
  }
}

@media all and (max-width: 768px) {
  .search-form-container {
    display: none;
  }
  .hidden-on-mobile {
    display: none;
  }
  .NaviMenu {
    width: 100%;
    background: #444;
  }
  .logo-text p,
  .login-text-container {
    font-size: 0.8rem;
  }
  .brand-logo {
    display: flex;
    align-items: center;
  }
  .footer-links {
    text-align: center;
    margin-bottom: 1rem;
  }
  #finePrint > div:first-child {
    width: 100%;
    justify-content: center;
  }
  #finePrint small {
    font-size: 0.75em !important;
  }
  .footer-logo-wrapper ul {
    text-align: center;
  }
  .footer-links p:first-of-type {
    padding-top: 1rem;
    margin: 0;
  }
  #finePrint img {
    height: 30px !important;
    margin-left: auto;
  }
  .main-category-promotion {
    font-size: 1.2rem;
  }
  nav .brand-logo img {
    height: 24px !important;
  }
  #finePrint small {
    margin: 0;
    font-size: 10px;
    height: 40px;
    line-height: 10px !important;
    display: flex;
    align-items: center;
    padding-left: 38px;
  }
  .item-main {
    width: 100%;
    margin: 0;
  }
  nav.nav-extended,
  nav.nav-extended .nav-wrapper,
  nav.nav-extended .header-container {
    height: 55px !important;
  }

  .preview-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-content5 {
    top: 34px;
  }
  .cate li a,
  .cate li span {
    font-size: 10px;
    text-transform: uppercase;
  }

  .cate li {
    padding: 2px;
  }
  .dropbtn5 i {
    margin-left: -5px;
  }
  #bd {
    margin-top: 89px !important;
  }

  .header-container[data-hidden="true"] ~ #bd {
    /*  padding-top: 80px !important; */
  }

  #leftGrid {
    width: 100%;
  }

  .specs-info-container {
    flex-direction: column;
  }

  .product-details-specs-container,
  .c-full {
    width: 100%;
  }

  .c-full {
    margin-top: 30px;
  }
  .c-left,
  .c-right {
    height: 100px;
  }

  .itemTitleMobile {
    margin-bottom: 10px;
    padding: 0 2.5% !important;
  }

  .itemTitleMobile,
  .product-page-item-code {
    padding: 0 2.5% !important;
  }

  .grand-total-container {
    background-color: #fff;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 60px;
    z-index: 1;
  }

  .add-to-cart-grand-total-wrapper {
    border-top: 2px solid #666 !important;
    padding: 5px 2.5% !important;
  }

  .add-to-cart-grand-total-wrapper p {
    font-size: 1rem;
  }

  .add-to-cart-btn-container {
    margin-bottom: 5px;
    padding: 0 2.5%;
  }

  .add-to-cart-btn-container button {
    margin-top: 5px !important;
    padding: 10px !important;
    font-size: 1rem !important;
  }
}
