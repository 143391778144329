/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2021 */



@font-face {
    font-family: 'navantgardeboldcdoblique';
    src: url('navantgardeboldcdoblique-webfont.woff2') format('woff2'),
         url('navantgardeboldcdoblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nboulevardregular';
    src: url('nboulevard-webfont.woff2') format('woff2'),
         url('nboulevard-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nfranklingothicdemi';
    src: url('nfranklingothicdemi-webfont.woff2') format('woff2'),
         url('nfranklingothicdemi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nhelveticablack';
    src: url('nhelveticablack-webfont.woff2') format('woff2'),
         url('nhelveticablack-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nmelioritalicitalic';
    src: url('nmelioritalic-webfont.woff2') format('woff2'),
         url('nmelioritalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noptimaregular';
    src: url('noptima-webfont.woff2') format('woff2'),
         url('noptima-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noptimaobliqueregular';
    src: url('noptimaoblique-webfont.woff2') format('woff2'),
         url('noptimaoblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noptimasmcaps';
    src: url('noptimasmcaps-webfont.woff2') format('woff2'),
         url('noptimasmcaps-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noptimasmcapsoblique';
    src: url('noptimasmcapsoblique-webfont.woff2') format('woff2'),
         url('noptimasmcapsoblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nplantinbolditalicbold';
    src: url('nplantinbolditalic-webfont.woff2') format('woff2'),
         url('nplantinbolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nplantinitalic';
    src: url('nplantinitalic-webfont.woff2') format('woff2'),
         url('nplantinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}