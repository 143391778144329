/* Webfont: HelveticaNeue-Bold */
@font-face {
  font-family: "75HelveticaBold";
  src: url("HelveticaNeue-Bold.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: DiotimaSC */
@font-face {
  font-family: "DiotimaSC";
  src: url("DiotimaSC.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: EurostileExt */
@font-face {
  font-family: "EurostileExt";
  src: url("Eurostile-ExtendedTwo.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: GoudyTextMT */
@font-face {
  font-family: "GoudyTextMT";
  src: url("GoudyTextMT.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: HelveticaNeue-Italic */
@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("HelveticaNeue-Italic.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: HelveticaNeue-Roman */
@font-face {
  font-family: "HelveticaNeue-Roman";
  src: url("HelveticaNeue-Roman.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: BauhausITCbyBT-Bold */
@font-face {
  font-family: "ITCBauhaus";
  src: url("BauhausITCbyBT-Bold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: ITC-BauhausMed */
@font-face {
  font-family: "ITC-BauhausMed";
  src: url("ITC-Bauhaus-Medium.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: KlangMTStd */
@font-face {
  font-family: "KlangMTStd";
  src: url("KlangMTStd.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: KuenstlerScriptMediumMed */
@font-face {
  font-family: "KuenstlerScriptMediumMed";
  src: url("KuenstlerScriptMedium.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NEBSGothic */
@font-face {
  font-family: "NEBSGothic";
  src: url("NEBSGothic.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NEBSScript-Two */
@font-face {
  font-family: "NEBSScript-Two";
  src: url("NEBSScript-Two.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NewCenturySchlbk-Roman */
@font-face {
  font-family: "NewCenturySchlbk";
  src: url("NewCenturySchlbk-Roman.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: Palatino-BoldItalic */
@font-face {
  font-family: "Palatino";
  src: url("Palatino-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: bold;
}

/* Webfont: TradeGothic-BoldCondTwenty */
@font-face {
  font-family: "TradeGothicCondEighteen";
  src: url("TradeGothic-BoldCondTwenty.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: UniversityRoman-Regular2 */
@font-face {
  font-family: "UniversityRoman";
  src: url("UniversityRoman-Regular2.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* NEW FONTS */

/* Webfont: ItcKabel-Medium */
@font-face {
  font-family: "ITCKabelMed";
  src: url("ItcKabel-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NAvantGardeBook */
@font-face {
  font-family: "NAvantGardeBook";
  src: url("NAvantGardeBook.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NHelveticaBlack */
@font-face {
  font-family: "NBlackHelvetica";
  src: url("NHelveticaBlack.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NBrushScript */
@font-face {
  font-family: "NBrushScript";
  src: url("NBrushScript.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NCopperplate32BC */
@font-face {
  font-family: "NCopperplateBC";
  src: url("NCopperplate32BC.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NEBSGothicBdCd */
@font-face {
  font-family: "NEBSGothicBdCd";
  src: url("NEBSGothicBdCd.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NEmbassyRegular */
@font-face {
  font-family: "NEmbassy";
  src: url("NEmbassyRegular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NAvantGardeExLt */
@font-face {
  font-family: "NExpAvantGardeLt";
  src: url("NAvantGardeExLt.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NGaramondBook */
@font-face {
  font-family: "NGaramondBook";
  src: url("NGaramondBook.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NHelveticaBold */
@font-face {
  font-family: "NHelveticaBold";
  src: url("NHelveticaBold.woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NHelveticaLightItalic */
@font-face {
  font-family: "NLightHelveticaItalic";
  src: url("NHelveticaLightItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NZapfChanceryMediumItalic */
@font-face {
  font-family: "NMedZapfChancery";
  src: url("NZapfChanceryMediumItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NMelior */
@font-face {
  font-family: "NMelior";
  src: url("NMelior.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NMeliorBold */
@font-face {
  font-family: "NMeliorBold";
  src: url("NMeliorBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NOldEnglish */
@font-face {
  font-family: "NOldEnglish";
  src: url("NOldEnglish.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NOptimaOblique */
@font-face {
  font-family: "NOptimaOblique";
  src: url("NOptimaOblique.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NParkAvenue */
@font-face {
  font-family: "NParkAvenue";
  src: url("NParkAvenue.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NSouvenirBold */
@font-face {
  font-family: "NSouvenirBold";
  src: url("NSouvenirBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NAvantGardeBookOblique */
@font-face {
  font-family: "NAvantGardeBookOblique";
  src: url("NAvantGardeBookOblique.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NBauhausBold */
@font-face {
  font-family: "NBauhausBold";
  src: url("NBauhausBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NBoulevard */
@font-face {
  font-family: "NBoulevard";
  src: url("NBoulevard.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NCheltenhamBook */
@font-face {
  font-family: "NCheltenham";
  src: url("NCheltenhamBook.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: NCheltenhamLight */
@font-face {
  font-family: "NCheltenhamLight";
  src: url("NCheltenhamLight.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NCheltenhamLightItalic */
@font-face {
  font-family: "NCheltenhamLightItalic";
  src: url("NCheltenhamLightItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NCheltenham-Bold */
@font-face {
  font-family: "NCheltenhamBold";
  src: url("NCheltenham-Bold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NEBSGothicBold */
@font-face {
  font-family: "NEBSGothicBold";
  src: url("NEBSGothicBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NEBSScript-Two */
@font-face {
  font-family: "NEBSScriptTwo";
  src: url("NEBSScript-Two.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NGraphiteRegular */
@font-face {
  font-family: "NGraphite";
  src: url("NGraphiteRegular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NHelvetica-Condensed */
@font-face {
  font-family: "NHelveticaCond";
  src: url("NHelvetica-Condensed.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NHelveticaNeueBold */
@font-face {
  font-family: "NHelveticaNeueBold";
  src: url("NHelveticaNeueBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* Webfont: NHelveticaNeue-Italic */
@font-face {
  font-family: "NHelveticaNeueItalic";
  src: url("NHelveticaNeue-Italic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

/* Webfont: NHelveticaNeue-Roman */
@font-face {
  font-family: "NHelveticaNeueRoman";
  src: url("NHelveticaNeue-Roman.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NHobo */
@font-face {
  font-family: "NHobo";
  src: url("NHobo.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NNewCenturySchlbkRoman */
@font-face {
  font-family: "NNewCenturySchlbk";
  src: url("NNewCenturySchlbkRoman.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: NPalatinoBoldItalic */
@font-face {
  font-family: "NPalatino";
  src: url("NPalatinoBoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: bold;
}

/* Webfont: NSpartanM */
@font-face {
  font-family: "NSpartanM";
  src: url("NSpartanM.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: RussellSquare */
@font-face {
  font-family: "RussellSquare";
  src: url("RussellSquare.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

/* Webfont: TradeGothic-BoldCondTwenty */
@font-face {
  font-family: "TradeGothicCondBold";
  src: url("TradeGothic-BoldCondTwenty.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}
