.wrapper {
  background-color: #fff;
}

.wrapper h1 {
  background-color: #0060f0;
  color: #fff;
  margin: 0;
  padding: 20px 10px;
  font-size: 1.2rem;
}

.wrapper p {
  font-size: 1rem;
  padding: 10px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(204, 203, 203);
}

.buttonsWrapper button {
  margin: 0;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  background-color: #0060f0;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  min-width: 150px;
}

.buttonsWrapper button:first-child {
  background-color: #666;
  color: #fff;
}
