h1.static-title {
  width: 95%;
  margin-left: 2.5%;
  padding-top: 14px;
  font-weight: 600;
}

.privacy-california,
.static-container {
  min-height: 1px;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.static-container p,
.static-container h4 {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.static-container > table.terms-and-condition-table {
  font-size: 1em;
  line-height: 1.875rem;
}

table.terms-and-condition-table tr:nth-child(even) {
    background: #f1f1f2;
}

.static-container ul {
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin-bottom: 2.5rem;
}

.static-container.contact-us {
  text-align: center;
}

.static-container a, .static-container.tel{
  color: #0060f0;
}

.static-container a:hover,
.static-container a:active,
.static-container a:focus,
.static-container a:visited {
  color: #0060f0 !important;
  text-decoration: underline;
}
