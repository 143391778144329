.wrapper {
  height: 4px;
  overflow: hidden;
  position: relative;
  background-color: #1f638c;
}

.bar {
  background-color: #c4eafb;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
  width: auto;
}

.first {
  animation: bar1 2.1s cubic-bezier(0.66, 0.8, 0.7, 0.4) infinite;
}

.second {
  animation: bar2 2.1s cubic-bezier(0.66, 0.8, 0.7, 0.4) infinite;
}

@keyframes bar1 {
  0% {
    left: -35%;
    right: 120%;
  }
  55% {
    left: 100%;
    right: -80%;
  }
  100% {
    left: 100%;
    right: -80%;
  }
}

@keyframes bar2 {
  0% {
    left: -190%;
    right: 100%;
  }
  55% {
    left: 105%;
    right: -9%;
  }
  100% {
    left: 105%;
    right: -9%;
  }
}
